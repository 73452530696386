import { useEffect, useState } from "react";
import { NodeCardRule } from "../../controller/node-card-rule";
import { Datum, DatumType, Node, NodeType } from "../../model/database";
import { Result } from "./result";
import { useDatabase, useUpdateRevision } from "./use-database";

export type NodeInfo = {
    node: Node;
    nodeType: NodeType;
    datums: readonly Datum[];
    datumTypes: readonly DatumType[];
    cardRule: NodeCardRule;
};

/**
 * 機器情報をIndexに従ってソートする処理
 * @param NodeInfo
 * @returns ソートされた機器情報配列
 */
function sortNodeInfos(NodeInfos: NodeInfo[]): NodeInfo[] {
    return NodeInfos.sort((a: NodeInfo, b: NodeInfo) => {
        if (a.node.treeIndex == null) {
            return 1;
        }
        if (b.node.treeIndex == null) {
            return -1;
        }
        return a.node.treeIndex - b.node.treeIndex;
    });
}

/**
 * Node配列から各NodeのNodeType、DatumType配列、Datum配列を取得する
 * @param nodes Node配列
 * @param options オプション
 * @returns
 */
export function useNodeInfos(
    nodes?: readonly Node[],
    { mainDatumOnly = false }: useNodeInfos.Options = {},
): Result<NodeInfo[]> {
    const datumTable = useDatabase().datums;
    const datumTypeTable = useDatabase().datumTypes;
    const nodeTypeTable = useDatabase().nodeTypes;
    const datumRevision = useUpdateRevision(datumTable);
    const datumTypeRevision = useUpdateRevision(datumTypeTable);
    const nodeTypeRevision = useUpdateRevision(nodeTypeTable);
    const [result, setResult] = useState<Result<NodeInfo[]>>({});

    useEffect(() => {
        if (!nodes) {
            return undefined;
        }
        const ac = new AbortController();
        const getNodeInfo = async (
            node: Node,
            signal: AbortSignal,
        ): Promise<NodeInfo> => {
            const nodeType = await nodeTypeTable.get(node.typeId, { signal });

            const datumTypes = await datumTypeTable.findGreedily(
                {
                    index: "listDatumTypesByNodeTypeId",
                    nodeTypeId: node.typeId,
                },
                { signal },
            );

            const mainDatumType =
                datumTypes?.[nodeType?.mainDatumIndex ?? -1] ?? undefined;

            const datums = await datumTable.findGreedily(
                {
                    index: "listDatumsByNodeIdTypeId",
                    nodeId: node.id,
                    typeId: mainDatumOnly
                        ? { eq: mainDatumType?.id ?? null }
                        : undefined,
                },
                { signal },
            );

            // datums.data は順序が不定なので、順序がきちんとしている datumTypes.data から
            // map して順序を整える
            const data = datumTypes
                .map((dt) => datums?.find((d) => d.typeId === dt.id))
                .filter((d): d is Datum => d != null);

            const cardRule = new NodeCardRule(node, nodeType, data, datumTypes);
            return {
                node,
                nodeType,
                datums: data,
                datumTypes,
                cardRule,
            };
        };

        Promise.all(nodes.map((node) => getNodeInfo(node, ac.signal))).then(
            (result) => {
                if (ac.signal.aborted) {
                    return;
                }
                setResult({ data: sortNodeInfos(result) });
            },
            (error) => {
                if (ac.signal.aborted) {
                    return;
                }
                setResult({ error });
            },
        );

        return () => {
            ac.abort();
        };
    }, [
        datumRevision,
        datumTable,
        datumTypeRevision,
        datumTypeTable,
        nodeTypeRevision,
        nodeTypeTable,
        nodes,
        mainDatumOnly,
    ]);

    return result;
}

export namespace useNodeInfos {
    export type Options = {
        /**
         * mainIndex の Datum のみを取得するフラグ
         */
        mainDatumOnly?: boolean;
    };
}
